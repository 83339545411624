import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="text-3xl font-bold underline">
        <p>
          Baltini Movie TSX
        </p>
      </header>
    </div>
  );
}

export default App;
